@use "mixins" as *;
.tooltip__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip {
    text-align: left;
    display: block;
    position: absolute;
    border: .4px solid var(--color-border-submit);
    background-color: var(--content-bg--color-light);
    padding: .5rem;
    border-radius: var(--radius);
    z-index: 999;

    &.-hidden {
        display: none;
    }
}

.tooltip-text {
    &.-title {
        @include subtitle;
    }

    &.-subtitle {
        @include body;

        &.-blue {
            color: var(--color-text-submit);
        }
    }
}
